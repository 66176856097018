<template>
	<div>
		<v-app-bar app :class="{ bar: isTransition }">
			<div><v-icon>$vuetify.icons.bigbearvpn</v-icon> BigBearVPN</div>			
			<div class="right">
				<v-btn class="ma-2" icon v-show="this.$vuetify.theme.dark"><v-icon @click="changeTheme()">$vuetify.icons.sun</v-icon></v-btn>
				<v-btn class="ma-2" icon v-show="!this.$vuetify.theme.dark"><v-icon @click="changeTheme()">$vuetify.icons.moon</v-icon></v-btn>

				<v-btn class="info" to="/overview">
					<v-icon>$vuetify.icons.my</v-icon>
					会员中心
				</v-btn>
			</div>
		</v-app-bar>

		<v-main style="padding: 20px;">
			<v-container fluid>
				<v-alert dense dark color="info accent-4" elevation="2">
					订阅会员请点击右上角"会员中心" 
				</v-alert>


                <v-card outlined elevation="3" class="mt-5">
                    <v-card-title>教程</v-card-title>
                    <v-card-text>
                        <v-btn text x-large outlined class="ml-2 mt-2 custom-btn" href="https://video.bigbear01.site" target="_blank">
                            <span>🔗视频使用教程</span>
                        </v-btn>
                    </v-card-text>
                </v-card>

                <v-card outlined elevation="3" class="mt-5">
                    <v-card-title>大熊客户端(手机端有提供免费节点)</v-card-title>
                    <v-card-text>
                        <v-btn text x-large outlined class="ml-2 mt-2 custom-btn" href="https://apps.apple.com/app/id1594060308" target="_blank">
                            <v-icon dense>$vuetify.icons.ios</v-icon>
                            <span>Mac, iPhone下载<br>(需海外App Store)</span>
                        </v-btn>

                        <v-btn text x-large outlined class="ml-2 mt-2" href="https://wwxd.lanzouw.com/bigbear-android" target="_blank">
                            <v-icon dense>$vuetify.icons.android</v-icon>
                            <span>&nbsp;Android 下载</span>
                        </v-btn>

						<v-btn text x-large outlined class="ml-2 mt-2" href="https://resource.bigbear01.site/static/bigbear-win.zip">
							<v-icon dense>$vuetify.icons.windows</v-icon>
							<span>&nbsp;Windows 下载(不支持Win7)</span>
						</v-btn>

                    </v-card-text>
                </v-card>

                <v-card outlined elevation="3" class="mt-5">
                    <v-card-title>第三方客户端 (导出节点到第三方客户端模式)</v-card-title>
                    <v-card-text>
                        <v-btn text x-large outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/v2rayng.html" target="_blank"><v-icon dense>$vuetify.icons.android</v-icon>V2-NG(安卓)使用教程</v-btn>
                        <v-btn text x-large outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/shadowrocket.html" target="_blank"><v-icon dense>$vuetify.icons.ios</v-icon>&nbsp;小火箭(iPhone)使用教程</v-btn>
                        <v-btn text x-large outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/v2rayn.html" target="_blank"><v-icon dense>$vuetify.icons.windows</v-icon>V2-N(Windows)使用教程</v-btn>
                        <v-btn text x-large outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/clash_verge.html" target="_blank"><v-icon dense>$vuetify.icons.mac</v-icon><v-icon dense>$vuetify.icons.windows</v-icon>Verge(Mac&Windows)使用教程</v-btn>
                    </v-card-text>
                </v-card>
			</v-container>
		</v-main>
	</div>
</template>

<script>
export default {
    data: () => ({
		isTransition: false,
        selected: 0,
        items: [
			{ product_id: "pro_001", time: "1天(体验套餐)", price: 1.0, traffic: "5GB", },
            { product_id: "pro_002", time: "1个月", price: 8.0, traffic: "100GB/月", },
			{ product_id: "pro_003",time: "3个月",price: 18.0,traffic: "100GB/月" },
        ],
    }),

	methods:{
		changeTheme(){
			this.isTransition = true			
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
		}
	}
}
</script>

<style scoped>
	.right{
		position: absolute;
		right: 30px;
	}
	.custom-btn {
		max-width: 100%; /* 设置最大宽度 */
		overflow: hidden; /* 防止内容溢出 */
		text-align: left; /* 文本对齐 */
		white-space: normal; /* 允许换行 */
		word-wrap: break-word; /* 在单词间断行 */
	}

	.custom-btn .v-icon {
		margin-right: 8px; /* 图标与文本之间的间距 */
	}
</style>